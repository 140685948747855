.header-card-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 12px;
	padding: 10px;
}

@media (max-width: 576px) {
	.header-card-container {
		justify-content: start !important;
		flex-wrap: nowrap !important;
		overflow: scroll !important;
		padding: 10px;
	}
}

.header-card-category {
	min-height: 40px;
}

.header-card-wrapper {
	max-width: calc(23% - 12px);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

@media (max-width: 1000px) {
	.header-card-wrapper {
		flex: 1 1 calc(50% - 12px); /* 2 cards per row on smaller screens */
		max-width: calc(50% - 12px);
	}
}

@media (max-width: 576px) {
	.header-card-wrapper {
		width: 200px;
		flex: 0 1 200px; /* 1 card per row on mobile */
		max-width: 200px;
	}
}
