/* InventoryCardList.css */
.deal-card-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 12px;
}

.deal-card-wrapper {
	flex: 1 1 calc(25% - 12px); /* 4 cards per row */
	max-width: calc(25% - 12px);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

@media (max-width: 1300px) {
	.deal-card-wrapper {
		flex: 1 1 calc(33% - 12px); /* 2 cards per row on smaller screens */
		max-width: calc(33% - 12px);
	}
}

@media (max-width: 1000px) {
	.deal-card-wrapper {
		flex: 1 1 calc(50% - 12px); /* 2 cards per row on smaller screens */
		max-width: calc(50% - 12px);
	}
}

@media (max-width: 576px) {
	.deal-card-wrapper {
		flex: 1 1 100%; /* 2 cards per row on mobile screens */
		max-width: 100%;
	}
}

.card-stats {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
