.unauthorized-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .unauthorized-content__icon {
    font-size: 70px;
    margin-bottom: 3rem;
  }
  
  .unauthorized-content__message {
    /* Any text styles specific to this message can go here */
  }
  