.inv-card-title {
	font-size: 1.1em;
	height: 50px;
	overflow: hidden;
}

@media (max-width: 576px) {
	.inv-card-title {
		font-size: 1.75em;
	}
}
