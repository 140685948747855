/* dark mode style */
.dark .ReactTable {
	color: #f7f7f7 !important;
}

.dark * {
	box-shadow: none;
}

.dark .main-panel {
	background: #090a0b !important;
}

.dark h2,
.dark h1,
.dark h3,
.dark h4,
.dark h5,
.dark p,
.dark .card h1,
.dark .card-stats-active .btn,
.dark .card label,
.dark * label,
.dark .css-1uccc91-singleValue {
	color: white !important;
}

.dark .css-1rhbuit-multiValue {
	background-color: gray;
}

.dark .ReactTable .-pagination .-btn {
	background-color: #014b84;
}

.dark .rt-tr -odd:hover,
.dark tr:hover {
	background-color: #090a0b !important;
}

.dark thead > tr:hover {
	background-color: inherit !important;
}

.dark .ReactTable .rt-table .rt-td {
	border-top: 1px solid rgba(143, 143, 143, 0.267);
	border-right: 1px solid rgba(143, 143, 143, 0.267);
	text-align: center;
}

.dark .ReactTable .rt-table {
	border: none;
}

.dark .table thead th {
	background-color: #2c2f36;
}

.dark .btn-surface.active {
	background-color: #383838;
	color: #f7f7f7;
}

.dark .table-bordered td,
.table-bordered th {
	border: 1px solid #000;
}

.dark hr {
	background-color: darkgray;
}

.dark .dropdown-menu {
	background: #1a1b1d;
}

.dark.btn-surface:focus {
	color: inherit;
}

.dark .btn-link:hover {
	background-color: #014b84 !important;
}

.dark .customer-profile {
	max-height: 128px;
	margin-bottom: 0.3rem;
	box-shadow: 0 0 2px lightgrey;
}

.dark .form-control,
.dark .form-control:focus,
.dark .modal-content {
	background-color: #1a1b1d;
	color: #f7f7f7;
	border-color: #444444;
}

.dark .modal-header {
	background-color: #1a1b1d;
	border: none;
}

.dark .btn-primary {
	background-color: #014b84 !important ;
}

.dark .react-select .react-select__control {
	border: 1px solid #1a1b1d;
}

.dark .card-stats-active .card-footer .btn.btn-sm.btn-link {
	background-color: inherit;
	color: inherit;
}

.dark .bg-white {
	background: #2a2d34 !important;
	box-shadow: none !important;
}

.dark .card-stats-active hr {
	color: #f7f7f7 !important;
	border: 1px solid #444444;
}

.dark .card-stats-active .card-body,
.dark .card-stats-active .card-footer {
	color: white;
	background-color: #000000;
}

.dark .card {
	box-shadow: inherit;
	background-color: #2c2f36 !important;
}

.dark .swal2-popup {
	background: #2a2d34 !important;
}
.dark .card-plain {
	background: none !important;
}

.dark .sidebar[data-color="sidebar"]:after,
.off-canvas-sidebar[data-color="sidebar"]:after {
	background: #1a1b1d;
}
.dark .sidebar .nav .collapse .nav {
	background: #2a2d34 !important;
}

.dark .action-box {
	background-color: #3b3c3e;
}

.dark .btn-success {
	color: #f7f7f7;
	background-color: #145c32; /* Darker green for dark mode */
}

.dark .btn-success:hover {
	color: #f7f7f7;
	background-color: #104628;
	border-color: #0e3b23;
}

.dark .btn-check:focus + .btn-success,
.dark .btn-success:focus {
	color: #f7f7f7;
	background-color: #104628;
	border-color: #0e3b23;
	box-shadow: 0 0 0 0.25rem rgba(20, 92, 50, 0.5); /* Adjusted to match the dark mode */
}

.dark .inventory-select div {
	background-color: rgba(0, 0, 0, 1);
}

.dark * {
	color: lightgrey !important;
}

.dark .form-control:disabled {
	background-color: rgba(120, 120, 120, 0.25);
}

.dark .dropdown-menu {
	color: rgb(245, 244, 244);
}

.dark .card-stats-active {
	background-color: #171717 !important;
	box-shadow: none !important;
	border-radius: 12px;
	color: rgb(200, 200, 200);
	border: 1px solid #444444;
}

.dark .btn-surface:hover,
.dark .nav-pill-bar .nav-item a:hover {
	background-color: #262729;
	color: white;
	text-decoration: none;
}

.dark .btn-surface.active {
	background-color: #171717;
}
