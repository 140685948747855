/* Wrapper for the component */
.multi-image-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-flow: row wrap;
	margin-bottom: 1rem;
	padding: 1.5rem 2rem;
	width: auto;
	max-width: 80%;
	border: 2px solid rgb(17, 17, 17);
}

/* Style for the Add Image icon -- START -- */
.multi-image-file-upload {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 1.5rem;
	max-width: 10rem;
	border-radius: 12px;
	transition: 0.3s ease-in-out;
}

.multi-image-file-upload:hover {
	-webkit-transform: scale(1.12);
	-ms-transform: scale(1.12);
	transform: scale(1.12);
	transition: 0.3s ease;
	color: rgba(12, 65, 96, 0.7);
	fill: rgba(12, 65, 96, 0.7);
}

.multi-image-file-upload input {
	visibility: hidden;
}

.multi-image-file-upload span {
	font-size: 0.672em;
}
/* Style for the Add Image icon -- END -- */

/* Container to hold uploaded images -- START -- */
.multi-image-container {
	cursor: pointer;
	position: relative;
	display: inline-block;
	width: 200px;
	height: 135px;
}

.multi-image-container img {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	padding: 0.225rem;
	transition: 0.3s ease-in-out;
}

.multi-image-container img:hover {
	-webkit-transform: scale(0.93);
	-ms-transform: scale(0.93);
	transform: scale(0.93);
	transition: 0.3s ease;
}
/* Container to hold uploaded images -- END -- */

/* Delete icon on uploaded image -- START -- */
.multi-image-delete:hover::after {
	background: rgba(202, 6, 6, 0.9);
}

.multi-image-delete::after {
	content: "x";
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	height: 20px;
	text-align: center;
	background: rgba(202, 6, 6, 0.6);
	border-radius: 50%;
	display: block;
	color: white;
}
/* Delete icon on uploaded image -- END -- */

/* Uncropped image warning icon -- START -- */
.multi-image-uncropped {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 5em;
	transform: translate(-50%, -50%);
	color: rgba(255, 255, 255, 0.6);
	z-index: 10;
}

.multi-image-uncropped:hover {
	color: rgba(255, 255, 255, 0.8);
}
/* Uncropped image warning icon -- END -- */

/* Error message styles -- START --*/
.multi-image-errors {
	cursor: default;
	display: flex;
	align-content: flex-start;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.multi-image-error-title {
	margin-top: 3rem;
	font-weight: 600;
	font-size: 1.125em;
}

.multi-image-error-message {
	font-size: 1.025em;
}

.multi-image-error-message li {
	line-height: 1.7rem;
}
/* Error message styles -- END --*/

/* Other styles -- START -- */
.multi-image-bold-text {
	font-weight: bold;
}

.multi-image-upload-button {
	border-width: 2px;
	font-weight: 600;
	font-size: 0.85em;
	line-height: 1.6em;
	text-transform: uppercase;
	border: none;
	margin: 6px 1px;
	border-radius: 3px;
	margin-top: 3.25rem;
	padding: 11px 22px;
	cursor: pointer;
	white-space: nowrap;
	background: rgb(12, 65, 96);
	color: #ffffff;
	transition: all 150ms linear;
}

.multi-image-upload-button:hover {
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
	transition: 0.3s ease;
	background: rgba(12, 65, 96, 0.9);
}
/* Other styles -- END -- */

/*.multi-image-selected::after {
	content: "✔";
	position: absolute;
	right: 14px;
	top: 14px;
	width: 20px;
	height: 20px;
	text-align: center;
	background: #65c8e6;
	border-radius: 50%;
	display: block;
	color: white;
}

.multi-image-unselected {
	display: none;
} */

/* .multi-image-scale {
	/* -webkit-transform: scale(0.93);
	-ms-transform: scale(0.93);
	transform: scale(0.93); */
/* box-shadow: 0 2px 15px #acacac; */
/* border: 3px solid #65c8e6; */
