.message-template-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	column-count: 5;
	column-gap: 1rem;
}

.message-template-list li {
	cursor: pointer;
	padding: 0.425rem;
	display: inline-block;
	width: 100%;
}

.message-template-list li:hover {
	background: border-box rgba(236, 236, 236, 0.6);
	border-radius: 4px;
	/*font-weight: 600;*/
}

@media (max-width: 750px) {
	.message-template-list {
		column-count: 3;
	}
}
