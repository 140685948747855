
.deal-card-title {
    font-size: 1.4em;
}

@media (max-width: 576px) {
    .deal-card-title {
        font-size: 1.75em;
    }
}

.deal-card-content{
    min-height: 175px;
}