/* Style for the Upload Button -- START -- */
.file-uploader-upload {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 10rem;
	transition: 0.3s ease-in-out;
}

.file-uploader-upload:hover {
	-webkit-transform: scale(1.08);
	-ms-transform: scale(1.08);
	transform: scale(1.08);
	transition: 0.3s ease;
	color: rgb(28, 119, 195);
}

.file-uploader-upload i {
	font-size: 1.5em;
	color: rgb(28, 119, 195);
}

.file-uploader-upload input {
	display: none;
}

.file-uploader-upload span {
	margin-top: 0.3rem;
	font-size: 0.95rem;
}
/* Style for the Upload Button -- END -- */

/* Style for the Upload Lists -- START -- */
.file-uploader-list {
	cursor: default;
	display: flex;
	align-content: flex-start;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 1.5rem;
}

.file-uploader-list-title {
	margin-top: 3rem;
	font-weight: 600;
	font-size: 1.195em;
}

.file-uploader-list ul {
	border: 1px solid rgb(180, 180, 180);
	font-size: 1.225em;
	width: 100%;
	padding: 0;
}

.file-uploader-list li {
	list-style: none;
	line-height: 1.7rem;
	border-bottom: 2px solid rgb(202, 202, 202);
	padding: 0.6rem 0 0.6rem 0.75rem;
}

.file-uploader-list li:last-child {
	border-bottom: none;
}
/* Style for the Upload Lists -- END -- */
