html * {
	transition-property: all !important;
	transition-duration: 0.2s ease-in-out !important;
}

.logo {
	max-width: 100%;
	margin-bottom: 0;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-top: 1rem;
	display: flex;
}
.main-panel {
	min-height: 100vh;
}
.miniLogo {
	height: 35px;
	display: grid;
	margin-bottom: 10px;
}

/* DealContent.css */

.row.d-flex.align-items-stretch {
	display: flex;
	align-items: stretch;
}

.card.h-100 {
	height: 100%;
}

hr,
.hr {
	margin: auto;
}

.cursor-pointer {
	cursor: pointer;
}
.login-form {
	max-width: 350px;
}

.login-page,
forgotten-password {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-position: center;
	background: rgb(63, 94, 251);
	background: radial-gradient(
		circle,
		rgba(63, 94, 251, 1) 0%,
		rgba(0, 78, 137, 1) 100%
	);
}

.toggle-btn {
	position: absolute;
	bottom: 1rem;
	z-index: 99;
	right: 3em;
	cursor: pointer;
	font-size: 0.7rem;
	text-decoration: underline;
}

a:hover,
.a:hover {
	text-decoration: none;
}
.dropdown-menu {
	min-width: 0 !important;
}

.sidebar-logo {
	max-width: 100%;
}
/* override bs class rounding */
.rounded {
	border-radius: 12px !important;
}

.gallery-image {
	max-width: 350px;
}

.inv-description {
	min-height: 250px;
	height: "auto";
	overflow: scroll;
}

/* constrain header img */
.headerImg {
	max-width: 80%;
}

.nav-pills-primary .nav-item .nav-link,
.nav-pills-secondary .nav-item .nav-link {
	color: #6e6e6e !important;
}
.larger-tabs .nav-link {
	font-size: 1.25rem; /* Increase font size */
	padding: 0.75rem 1.5rem; /* Adjust padding for larger tabs */
}

.bg-primary > .nav-item > .nav-link {
	color: white !important;
}

.sale-container {
	display: flex;
	flex-wrap: wrap;
}

.sale-item {
	width: 100%;
	margin-bottom: 1rem;
}

@media (min-width: 1800px) {
	.sale-item {
		width: 50%;
		padding: 0 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

@media (min-width: 1800px) {
	.sale-item .row {
		justify-content: space-between;
		align-items: center;
	}
}

.sale-item .row .col-6 {
	display: flex;
	align-items: center;
}

.stock-cell-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.stock-cell-item {
	width: 100%;
	padding: 2px 0;
}

.singleCell .stock-cell-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.nav-pills-primary .nav-item .nav-link.active,
.nav-pills-secondary .nav-item .nav-link.active {
	color: #fff !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
	box-shadow: none;
}

/* branded button links */
.btn-link-danger {
	background-color: transparent !important;
	color: #c1292e;
}

.btn-link-danger:hover {
	background-color: #cc2936 !important;
	color: white !important;
}
/* branded button links */
.btn-link-warning:hover {
	background-color: #fcd581 !important;
	color: #777 !important;
}

/* branded button links */
.btn-link-success {
	background-color: #329f5b !important;
	color: white !important;
}

/* branded button links */
.btn-link-success:hover {
	background-color: #1ada63 !important;
}
/* custom large top margin */

.mt-10 {
	margin-top: 25%;
}

/* add bg to form inputs */

.input-group-prepend .input-group-text {
	background-color: #b8d5ff;
	padding-right: 0.5rem !important;
}

.dropdown-item > a:hover {
	color: white !important;
}

.sidebar .logo .simple-text .logo-img img,
.logo-img {
	width: 80%;
	height: 80%;
	background-color: none !important;
	padding: 3px;
}

.card-stats .card-body .numbers .card-category {
	font-size: 14px;
	line-height: 1.4em;
}

.vertical-align {
	margin-top: 20%;
}

.sidebar .logo a.logo-normal {
	padding: 5px 0 8px;
}

.formsCard {
	overflow: scroll;
}

.bigText {
	font-size: 1.5rem !important;
}
.left-align label {
	text-align: left;
}

.plus-sign {
	line-height: 5.8em;
}
@media (max-width: 768px) {
	.plus-sign {
		line-height: 6.4em;
	}
}

/* Wrapper style */
.inv-desc-box-wrapper {
	display: block;
	min-height: 20px; /* minimum height of the textarea */
	overflow: hidden;
}

td > input {
	position: inherit !important;
}

.card-header {
	padding: 0 0.5rem;
}

/* Textarea style */
.inv-desc-box {
	width: 100%;
	height: 100% !important;
	min-height: 100%;
	overflow: hidden;
}

/* Default min-height for larger screens */
.invDescription {
	min-height: 175px;
}

/* Increase min-height for large screens (1420px and up) */
@media (min-width: 1420px) {
	.invDescription {
		min-height: 250px;
	}
}

/* Increase min-height for medium screens (1020px to 1419px) */
@media (min-width: 1020px) and (max-width: 1419px) {
	.invDescription {
		min-height: 300px;
	}
}

/* Increase min-height for small screens (569px to 1019px) */
@media (max-width: 1019px) and (min-width: 569px) {
	.invDescription {
		min-height: 450px;
	}
}

/* Increase min-height for mobile screens (max-width: 568px) */
@media (max-width: 568px) {
	.invDescription {
		min-height: 700px;
	}
}

.h3,
h3 {
	margin-bottom: 0 !important;
}

.row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.customNav {
	box-shadow: 0 0 4px rgb(179, 178, 178);
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

b,
strong {
	font-weight: lighter;
}
.hide {
	display: none !important;
}

.dealImg {
	max-height: 100px;
}

/* camera styles */
.react-html5-camera-photo .video-wrapper video {
	height: auto !important;
	width: 30% !important;
	margin: 0 auto;
}

.radioFont {
	font-weight: lighter;
}

.rt-td > div > img,
.singleCell > img {
	width: auto;
	max-height: 6.5em !important;
}

.rt-td > div > img,
.singleCell {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* @media (min-width: 1400px) {
	.rt-td > div > img,
	.singleCell > img {
		width: auto;
		max-height: 70px !important;
	}
} */

.SiriusXM {
	min-width: 150px;
	max-width: 250px;
	width: 100%;
}

/* hide elements on smaller screens */
@media (max-width: 1400px) {
	.noMobile,
	#noMobile {
		display: none !important;
	}
}

/* hide elements on smaller screens */
@media (min-width: 1400px) {
	.noDesktop,
	#noDesktop {
		display: none !important;
	}
}

.responsive-table {
	overflow-x: auto;
	width: 100%;
}

/* styles.css */
@media (max-width: 768px) {
	.table thead th {
		position: relative;
		z-index: 1;
		background: #f8f9fa; /* Adjust based on your theme */
		top: 0;
	}
	.table-bordered thead th {
		border: 1px solid #dee2e6;
	}
}

/* hide elements at 768px */
@media (max-width: 768px) {
	/* .onMobileScreen,
	#onMobileScreen, */
	.ReactTable th {
		display: block !important;
	}

	.historyButtons img {
		height: 120px;
	}

	.historyButtons,
	.modal-dialog {
		width: 100% !important;
		margin: 10px auto;
		padding: 10px;
	}

	.partner-card {
		width: 250px;
		cursor: pointer;
	}

	.sticky-header {
		position: sticky;
		top: 60px;
		z-index: 999999999;
	}

	.deal-total {
		text-align: center;
	}
	.row .form-group,
	.form-group {
		padding-right: 15px;
		padding-left: 15px;
	}

	.card td,
	.card th,
	.forms-table tr,
	.form-group .forms-table {
		min-width: 180px;
		width: 100% !important;
		float: left;
		margin: 0 auto;
	}

	.card th:first-child {
		min-width: 0 !important;
	}

	table,
	tbody {
		overflow: hidden;
	}

	tr {
		display: flex;
		flex-wrap: nowrap;
		padding: 10px 0;
	}
	td {
		display: block;
		flex: 1; /* to evenly distributs flex elements */
		border: none !important;
		padding: 0;
		margin: 0px !important;
		position: relative;
	}
}

.tabNav > li > a.active {
	text-decoration: underline !important;
}

.tabNav:hover {
	cursor: pointer;
}

.tabNav {
	display: flex;
	justify-content: center;
}

#actionDropdownItem:hover {
	background: none;
}

#actionDropdownItem div,
#actionDropdownItem button {
	width: 100%;
	text-align: center;
}

iframe[style*="z-index: 2147483647"] {
	display: none !important;
}

.pageHeader {
	margin-top: 3rem;
	padding-bottom: 0.5rem;
	min-height: 53px;
}

@media (max-width: 768px) {
	.pageHeader {
		margin-top: 0;
	}
}

/* line height made even for sidebar icons */
.sidebar
	.sidebar-wrapper
	> .nav
	[data-toggle="collapse"]
	~ div
	> ul
	> li
	> a
	i {
	line-height: 30px;
}

/* move to sass file */

.btn.btn-link-black {
	color: #000;
	background-color: transparent;
}

.rt-resizable-header-content {
	text-align: center;
}

.sidebar[data-color="sidebar"] .nav li a {
	color: #f7f7f7;
}

/* add red asterix to req fields */
.required:after {
	content: " *";
	color: red;
}

.font-xs {
	font-size: 0.8em;
}

/* Styling for table cells */
.singleCell {
	font-size: var(--base-font-size);
	padding: 0 3px;
	display: flex;
	justify-content: var(--cell-justify-content, center);
	align-items: center;
	flex-direction: row;
	overflow: hidden;
}

.table-auto-width tr > td:first-child,
.table-auto-width tr > th:first-child {
	width: auto !important;
	/* Override min-width for the first child */
	min-width: unset !important;
	/* If flex is causing issues, reset it */
	display: table-cell !important;
}

.status {
	font-size: 14px;
	font-weight: 400;
}
/*
.sidebar-mini .sidebar {
	display: block;
	z-index: 999999;
}
*/
.mt-half {
	margin-top: 0.8rem;
}

.lg-p {
	font-size: 1rem;
}

.pt-3-half {
	padding-top: 1.3rem !important;
}

.selector {
	min-width: 200px;
}

.main-panel .content {
	padding-left: 0;
	padding-right: 0;
}

table tbody tr td input {
	background-color: inherit;
}

.lenderImg {
	height: 80px !important;
	margin-bottom: 12px;
}

.bodyHeight {
	min-height: 110vh !important;
}

.adImage {
	max-height: 45px !important;
	margin-bottom: 10px;
}

.modal-xl {
	max-width: 94% !important;
}

.btn i,
.navbar .navbar-nav > a.btn i {
	margin-right: 0 !important;
}

/* InventoryListData.css */

/* Default table view */
.inventory-table {
	display: block;
}

/* Hide card view by default */
.inventory-card-list {
	display: none;
}

/* Switch to card view at 1400px */
@media (max-width: 1400px) {
	.inventory-table {
		display: none;
	}
	.inventory-card-list {
		display: block;
	}
}

.btn {
	outline: none !important;
	border: none;
}

/* hide elements on smaller screens */
@media (max-width: 1920px) {
	.sidebarText {
		display: none !important;
	}
}

.old-row {
	margin: 0 -15px !important;
}

.customer-profile {
	max-height: 128px;
	margin-bottom: 0.3rem;
	box-shadow: 0 0 2px darkgrey;
}

.hide {
	display: none;
}

.bottom-nav a {
	float: left;
	display: block;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

.bottom-nav a:hover {
	background: #f1f1f1;
	color: black;
}

.bottom-nav a.active {
	background-color: #04aa6d;
	color: white;
}

.btn-active {
	background-color: #04aa6d;
	color: #5a5a5a;
}

/* styles.css */
.toggle-button {
	background-color: inherit;
}

.toggleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.5rem 0;
}

@media (max-width: 768px) {
	.toggleContainer {
		justify-content: center;
		margin-bottom: 20px;
	}
}

/* floating save button */

.float-button {
	position: fixed;
	right: 20px;
	bottom: -115px;
	transition: all 0.2s ease-in 0s;
	z-index: 999;
	cursor: pointer;
	box-shadow: 0 0 2px 0px rgb(179, 178, 178);
	height: 150px;
	font-size: 16px;
	font-weight: 600;
}

.hi-z-index {
	z-index: 9999;
}

.float-button:hover {
	bottom: -110px;
	font-size: 18px;
}

.float-button-modal {
	position: fixed;
	right: 135px;
	bottom: 30px;
	left: 170px;
	transition: all 0.2s ease-in 0s;
	z-index: 9999;
	cursor: pointer;
	text-align: right;
}

.welcomeImage {
	width: 500px;
	height: 300px;
}
@media screen and (max-width: 768px) {
	.welcomeImage {
		width: 100%;
		height: 100%;
	}
}

.article-card {
	width: 18rem;
}
@media screen and (max-width: 768px) {
	.article-card {
		width: 100%;
	}
}

.headerToggle {
	position: absolute !important;
	top: 4px;
	left: 49%;
	z-index: 9999;
}
.pt-6 {
	padding-top: 3.5rem;
}

.col-xl-6 {
	flex: 0 0 49%;
}

.collapse-button {
	display: block;
	width: 100%;
}

.collapse-content.collapsed {
	display: none;
}

.collapsed-content.expanded {
	display: block;
}

@media (max-width: 768px) {
	.mobile-pb-1 {
		padding-bottom: 1rem;
	}
}

.btn-block + .btn-block {
	margin-top: 0 !important;
}

.attachmentBtnCol {
	justify-content: flex-start;
}

@media (max-width: 768px) {
	.attachmentBtnCol {
		justify-content: center;
	}
}

.modal-body {
	padding: 15px;
}

@media (max-width: 1300px) {
	.mobileHeading {
		padding-top: 5rem !important;
	}
}

/* lazy but i cant find in sass files... */

.vehicle-photo img {
	border-radius: 10px !important;
	overflow: hidden !important;
	margin-top: 10px;
}

.nav-pills-primary .nav-item .nav-link,
.nav-pills-secondary .nav-item .nav-link {
	box-shadow: 0 0 2px #00000042;
	border: 0;
	margin: 0;
	border-radius: 30px !important;
}
.css-12jo7m5 {
	font-size: 18px !important;
}

.nav-search,
.search {
	display: none;
}

.avatar {
	max-width: 30px;
	padding-top: 0.4rem;
}

.content {
	margin-top: 60px !important;
}

.main-panel {
	background-color: #f7f7f7 !important;
	transition: all 0rgb (173, 173, 173) c-bezier(0.685, 0.0473, 0.346, 1);
	transition-property: all;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel > .content {
	padding: 0;
}

.dropdown-item > p:hover {
	color: white !important;
}

.detailImg {
	max-height: 175px !important;
}

.modal-content {
	background-color: #f7f7f7;
}

.headTopNav {
	margin-top: -2.9rem !important;
	border-bottom: 1px solid lightgray;
}

.kbbLogo {
	max-width: 4rem;
	right: 0;
	position: absolute;
}

.favicon-gif {
	margin-bottom: 35px;
}

.letter {
	font-size: 14px;
	font-family: "Roboto!important";
	color: white;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	line-height: 40px;
}

.reminder {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	line-height: 40px;
}

.profile {
	border-radius: 50%;
	width: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 20px;
}

.red-close-icon {
	text-align: center;
	cursor: pointer;
	color: rgb(255, 255, 255);
	padding: 0.15rem 0.25rem;
	background: rgba(202, 6, 6, 0.6);
	border-radius: 50%;
	position: relative;
	top: 50%;
	right: 2em;
}

.red-close-icon i {
	font-size: 1.5rem;
}

.red-close-icon:hover {
	background: rgba(202, 6, 6, 0.9);
}

.text-messages-container {
	display: flex;
	flex-direction: column-reverse;
	overflow: auto;
	width: 100%;
	max-height: 30rem;
	margin: 0 auto;
	padding: 0.5rem;
}

.text-messages {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0.2rem;
}

#container-circles {
	position: absolute;
	left: 50%;
	bottom: 135px;
}

.camera-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 60%;
	z-index: 99999;
	padding-bottom: 1rem;
	background-color: black;
}

.camera-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 50%; /* Covering half the screen at the top */
	background: rgba(0, 0, 0, 0.7); /* semi-transparent black background */
}
.scroll-box {
	display: flex;
	justify-content: center;
	align-items: center;
}
@media screen and (max-width: 768px) {
	.scroll-box {
		justify-content: flex-start;
	}
}

.detail-header-card {
	min-width: 200px;
}

/*  action bar  */
.action-bar {
	overflow-x: auto;
	display: flex;
	position: relative;
	width: 100%;
	z-index: 100;
	min-height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	align-items: center;
}

@media (min-width: 768px) {
	.action-bar {
		justify-content: center;
		margin-left: 0em;
	}
}
@media (max-width: 768px) {
	.action-bar .btn {
		padding: 0.5rem 1rem !important;
	}
}
.action-bar .btn {
	font-size: 0.8rem;
	white-space: nowrap; /* Prevent text wrapping */
	display: flex; /* Ensure content is horizontally aligned */
	align-items: center; /* Center the text vertically */
}

.no-horizontal-scrollbar,
.action-bar,
.nav-pill-bar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.no-horizontal-scrollbar::-webkit-scrollbar,
.nav-pill-bar::-webkit-scrollbar,
.action-bar::-webkit-scrollbar {
	display: none;
}

.nav-pill-bar {
	display: flex;
	overflow-x: auto;
	gap: 10px;
	flex-wrap: nowrap;
	justify-content: flex-start;
	padding: 5px 0;
}
@media (max-width: 700px) {
	.nav-pill-bar,
	.action-bar {
		gap: 0px;
	}
}

@media (min-width: 1200px) {
	.nav-pill-bar,
	.action-bar {
		justify-content: center;
	}
}

td span div {
	text-wrap: balance;
}

/* Adding another overlay for the bottom */
.camera-overlay-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 50%; /* Covering half the screen at the bottom */
	background: rgba(0, 0, 0, 0.7);
}

.react-html5-camera-photo .video-wrapper video {
	height: auto !important;
	width: 100% !important; /* Making camera feed take full width */
	margin: 0 auto;
}

.text-messages-container::-webkit-scrollbar {
	width: 11px;
}

.text-messages-container::-webkit-scrollbar-track {
	background: rgb(231, 231, 231);
	border-radius: 25px;
}

.text-messages-container::-webkit-scrollbar-thumb {
	background: rgb(195, 195, 195);
	border-radius: 25px;
}

.text-messages-container::-webkit-scrollbar-thumb:hover {
	background: rgba(195, 195, 195, 0.8);
}

.text-messages div {
	display: flex;
	flex-direction: column;
	font-size: 0.985em;
	max-width: 15rem;
	margin-bottom: 0.3rem;
	padding: 0.4rem 0.3rem;
	line-height: 18px;
	border-radius: 17px;
}

.text-messages-time {
	font-size: 0.755em;
	margin-bottom: 0.1rem;
	padding: 0 0.2rem;
}

.text-messages-sent {
	align-self: flex-end;
	background: rgb(72, 189, 243);
	color: white;
}

.text-messages-sent .text-messages-time {
	align-self: flex-end;
	margin-left: 1rem;
}

.text-messages-received {
	align-self: flex-start;
	background: rgb(236, 236, 236);
	color: black;
}

.text-messages-sent div,
.text-messages-received div {
	white-space: pre-line;
}

.text-messages-received .text-messages-time {
	align-self: flex-start;
	margin-right: 1rem;
}

.text-messages-send-message {
	padding: 0.75rem 0.35rem 0 0.35rem;
	background: rgb(236, 236, 236);
}

.dropdown-menu {
	box-shadow: 0 0 6px rgb(179, 178, 178);
}

.ml-mobile {
	margin-left: 0.5rem;
}

@media (max-width: 768px) {
	.ml-mobile {
		margin-left: 2px;
	}
}

.close {
	position: absolute !important;
	top: 10px !important;
	right: 10px !important;
}

.text-messages-body {
	resize: none;
	padding: 0.75rem;
	border-radius: 10px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-top: -1rem;
}

.text-messages-body::-webkit-scrollbar {
	display: none;
}

.text-messages-preview {
	background: rgb(236, 236, 236);
}

.text-messages-preview-text {
	font-family: inherit;
	font-size: 1rem;
	padding: 0.85rem 1rem 1rem 1rem;
	white-space: pre-wrap;
}

.ReactTable .rt-th-check,
.ReactTable .rt-td-check {
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 12px 7px;
	transition: 0.3s ease;
	transition-property: width, min-width, padding, opacity;
	line-height: 2;
}
.react-select {
	width: 100px;
}
.react-select .react-select__control .react-select__indicators {
	width: 20px;
}
.forms .ReactTable .rt-tbody {
	display: revert;
}

.forms .singleCell {
	width: 100%;
	display: revert;
	text-align: left;
}

.forms .ReactTable .rt-tr {
	display: table;
}

.forms .ReactTable .rt-table .rt-td {
	text-align: revert;
	text-wrap: balance;
}

.ReactTable .rt-table {
	border: none;
}

/* Define CSS class for pink row */
.ReactTable .row-blue {
	background-color: lightblue; /* Set background color to blue */
}
.ReactTable .row-green {
	background-color: rgb(172, 241, 172); /* Set background color to green */
}
.ReactTable .row-yellow {
	background-color: lightyellow; /* Set background color to yellow */
}
.ReactTable .row-red {
	background-color: red; /* Set background color to red */
}

/* Optional: Define a hover effect */
.ReactTable .row-pink:hover {
	background-color: blue; /* Change to light coral on hover */
}

.float-end {
	float: right !important;
}

.table-caption {
	width: 100%;
	font-size: 1.57em;
	caption-side: top;
	text-align: center;
	color: black;
}

/** Twilio Blast Text Styles -- START -- **/
.blast-message-participants {
	max-height: 20rem;
	overflow-y: scroll;
	padding: 0.1rem 1rem 1rem 1rem;
}

.blast-message-participants table thead tr th {
	position: sticky;
	top: 0;
	background-color: #ddd;
	z-index: 10;
}

.blast-message-participants table tbody tr td p {
	color: rgb(235, 14, 14);
	font-style: italic;
	font-size: 0.85em;
	padding: 0;
	margin: 0;
}
/** Twilio Blast Text Styles -- END -- **/
.checkbox-spacing {
	margin-left: 1rem;
}

form.deal-sale div,
form.deal-terms-table div {
	min-width: 50%;
}

div.deal-summary input,
.regular-payment,
.final-payment {
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
	font-size: 16px;
	max-width: 50%;
	min-width: 150px;
	padding: 1px;
	color: #444;
}
div.deal-summary label {
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
	width: fit-content;
	display: inline;
	float: left;
	line-height: 125%;
	font-weight: normal;
}

.form-control {
	font-size: var(--base-font-size);
}

@media (max-width: 768px) {
	.form-control {
		font-size: 16px;
	}
}

@media (max-width: 1550px) {
	.full-width-col {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
}

.br-6 {
	border-radius: 6px !important;
}

.maxInput {
	max-width: 600px;
}

.minInput {
	min-width: 150px;
}

@media (min-width: 768px) {
	.minInput {
		min-width: 100%;
	}
}

.regular-payment,
.deal-total input,
.amount-financed input {
	font-weight: 600 !important;
	color: #069 !important;
}

.deal-type,
.deal-type p input {
	width: 100%;
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
	text-align: center;
}

input[type="checkbox"] {
	height: 20px;
	width: 20px;
}

.list-group-item {
	border: none;
}

.flooring-overview {
	border: none !important;
	box-shadow: none !important;
	border: none !important;
	font-weight: 600;
	text-align: left;
	font-size: 20px;
	max-width: 50%;
	min-width: 150px;
	padding: 1px;
	color: #444;
}

.searchIcon {
	margin-right: 0.4rem;
}

#searchInput {
	width: 350px;
}
@media (max-width: 768px) {
	#searchInput {
		width: 225px !important;
	}
}

.checkbox-box {
	background-color: transparent;

	height: auto;
	max-height: 50px;
	transition: all 300ms;
	display: table;
	max-width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.35rem 0.1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-clip: padding-box;
	border: 1px solid rgba(120, 120, 120, 0.5);
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

tr th:last-child,
tr td:last-child input,
tr td:last-child {
	text-align: right;
}

.text-right .form-control {
	text-align: right;
}

.checkbox-box .col-sm-3 {
	max-width: 100%;
	position: relative;
}

.checkbox-box .col-sm-3 input {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 1px;
	left: 25px;
}

.bold {
	font-weight: 600;
}
.app-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px; /* Adjust the gap as needed */
	justify-content: center;
	align-items: center;
}
.checkbox-columns {
	display: flex;
	justify-content: space-between;
}

.checkbox-column {
	flex: 1;
}

.checkbox-item {
	display: flex;
	align-items: center;
}

.flex-space-toggle {
	margin-left: 0rem;
}
@media (min-width: 1400px) {
	.flex-space-toggle {
		justify-content: space-around;
	}
}

.rendered-card {
	min-width: 300px;
	flex: 0 0 auto;
}

@media (max-width: 600px) {
	.rendered-card {
		min-width: 93vw !important;
	}
}

@media (max-width: 1000px) {
	.rendered-card {
		min-width: 275px;
	}
}

@media (min-width: 1400px) {
	.rendered-card {
		min-width: 300px;
	}
}

@media (min-width: 1680px) {
	.rendered-card {
		min-width: 320px;
	}
}

@media (min-width: 1920px) {
	.rendered-card {
		min-width: 350px;
	}
}

@media (min-width: 2800px) {
	.rendered-card {
		min-width: 400px;
	}
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.blinking-button {
	animation: blink 1s infinite;
}
.modal-minimized .modal-dialog {
	width: 300px; /* Adjust width as needed */
	margin: 0;
	position: fixed;
	bottom: 0;
	right: 0;
	left: auto;
	top: auto;
	transform: none;
}

.modal-minimized .modal-content {
	height: 60px; /* Adjust height as needed */
	overflow: hidden;
}

.modal-minimized .modal-header {
	padding: 0px; /* Adjust padding as needed */
}
