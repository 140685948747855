.all-values-margin {
	margin-left: 1rem;
	margin-right: 1rem;
	font-weight: 900;
}

.all-values-border {
	border-bottom: 1px solid black;
}

.all-values-border-spacing {
	border-top: solid transparent 40px;
}

.all-values-header {
	font-size: 2.425rem;
	margin-top: 3rem;
}

.all-values-body {
	font-size: 1.11rem;
}

.all-values-footer {
	bottom: 0;
	position: fixed;
}
