/* Parent container for the messages */
.recent-message-log {
	list-style: none;
	margin: 0;
	padding: 0 1rem;
	width: 100%;
	max-height: 100vh;
	overflow-y: auto;
}

/* Scrollbar style for the list of messages -- START -- */
.recent-message-log::-webkit-scrollbar {
	width: 10px;
}

.recent-message-log::-webkit-scrollbar-track {
	background: rgb(233, 232, 232);
	border-radius: 20px;
}

.recent-message-log::-webkit-scrollbar-thumb {
	background: rgba(198, 197, 197, 0.8);
	border-radius: 20px;
}

.recent-message-log::-webkit-scrollbar-thumb:hover {
	background: rgb(198, 197, 197);
}
/* Scrollbar style for the list of messages -- END -- */

/* Each message list item -- START -- */
.recent-message-log li {
	cursor: pointer;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem 0;
	margin-top: 1rem;
	position: relative;
}

.recent-message-log li:hover {
	background: rgb(242, 242, 242);
	border-radius: 15px;
}

.recent-message-log li:not(:last-child)::after {
	display: block;
	position: absolute;
	content: "";
	border-bottom: 2px solid #b8b8b8;
	width: 94%;
	left: 6%;
	margin: auto;
	bottom: -4px;
}

.recent-message-log li:nth-child(n + 2) {
	margin-top: 1rem;
}

/* Icon style -- START -- */
.recent-message-log-icon span {
	height: 3.25rem;
	width: 3.25rem;
	background-color: rgb(222, 222, 222);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
	margin-left: 0.5rem;
}

.recent-message-log-icon i {
	font-size: 1.5rem;
}
/* Icon style -- END -- */

/* Everything aside from the icon in each list -- START -- */
.recent-message-log-content {
	display: flex;
	flex-grow: 2;
}

.recent-message-log-content div:first-child {
	width: 70%;
}

.recent-message-log-content div:first-child :first-child {
	font-size: 1.125em;
	margin-bottom: 0.25rem;
}

.recent-message-log-content div:nth-child(2) {
	width: 30%;
	display: flex;
	justify-content: flex-end;
	margin-right: 0.5rem;
}

.recent-message-log li div p {
	margin: 0;
}
/* Everything aside from the icon in each list -- END -- */
/* Each message list item -- END -- */
