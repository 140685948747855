.book-sheet-page-margin {
	margin-left: 4rem;
	margin-right: 4rem;
}

.book-sheet-border {
	border-top: 1px dashed;
	margin-top: -1.2rem;
}

.book-sheet-header {
	font-size: 1.725rem;
	font-weight: 900;
}

.book-sheet-header-top {
	margin-top: 2rem;
	margin-bottom: -0.825rem;
}

.book-sheet-header-2 {
	font-size: 1.125rem;
	font-weight: 900;
}

.book-sheet-cost {
	margin-top: 6rem;
}

.book-sheet-footer {
	margin-top: 6rem;
}

.book-sheet-footer-margin {
	margin-bottom: -1.025rem;
}
